export enum Direction {
  Prev = 'PREV',
  Next = 'NEXT',
}

export enum FormName {
  AuthenticationForm = 'AUTHENTICATION_FORM',
  CreateQuestionForm = 'CREATE_QUESTION_FORM',
  QuestionSubmitForm = 'QUESTION_SUBMIT_FORM',
  RegistrationForm = 'REGISTRATION_FORM',
  RecoverPasswordForm = 'RECOVER_PASSWORD_FORM',
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

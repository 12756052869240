import { Lang } from 'atw-shared/utils'

export const APP_NAME = "Nums Don't Lie"
export const APP_NAME_SHORT = 'NDL'
export const APP_EMAIL = 'numsdontlie@gmail.com'
export const STRIPE_HOME_PAGE = 'https://stripe.com'

export const DATE_TIME_FORMAT = 'd MMMM yyyy, hh:mm:ss'

export const DATE_PICKER_LOCALES = {
  [Lang.EN]: 'en-US',
  [Lang.PL]: 'pl-PL',
}

export const TEN_MINUTES_IN_MS = 1000 * 60 * 10
export const MIN_REQ_TIME_MS = 200
export const MODAL_DIALOG_ENTER_MS = 200

export const AVATAR_SIZE = 300
export const COUNTRY_FLAG_SIZE = 48

export const DEFAULT_THEME = 'light'
export const DEFAULT_LANG = Lang.EN

export const YES = 'yes'
export const NO = 'no'
